
.devIconsBlurb {
    display: block;
}

.devIconsContainerLine1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.devIconsBorder {
    border: 2px solid;
    text-align: center;
    width: 40%;
    padding: 30px;
    margin: 40px;
    color: #cdcdcd;
    box-shadow: 0px 0px 17px -10px rgba(0,0,0,0.75);
}

.devIcons {
    font-size: 50px;
    margin: 5px;
}

a.devIcons:link {
    color: black;
    background-color: transparent;
    text-decoration: none;
}

a.devIcons:visited {
    color: black;
    background-color: transparent;
    text-decoration: none;
}

a.devIcons:hover {
    color: rgb(122, 122, 122);
    background-color: transparent;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .devIcons {
        font-size: 30px;
        margin: 5px;
    }
}