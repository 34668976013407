html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

.Home .introduction{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 20%;
}

span.intro-name, span.intro-upper {
    font-family: Josefin Sans,sans-serif;
    line-height: .9em;
    pointer-events: none;
}

span.intro-upper {
    font-weight: 300;
    margin: 20px 0;
    font-size: 2em;
}

span.intro-name {
    font-weight: 700;
    font-size: 5em;
    line-height: 1.2em;
    margin-left: -3px;
}

span.intro-lower {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Josefin Sans,sans-serif;
    font-weight: 300;
    margin: 20px 0;
    font-size: 2em;
    line-height: .9em;
    pointer-events: none;
}

span.intro-text {
    margin-right: 10px;
}

.typography-setting {
    font-family: Josefin Sans,sans-serif;
    font-weight: 400;
}

.Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
}
@keyframes blink {
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}

@media screen and (max-width:1400px){
    .Home .right_sidebar{
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    span.intro-upper {
        font-weight: 300;
        margin: 10px 0;
        font-size: 1em;
    }
    
    span.intro-name {
        font-weight: 700;
        font-size: 2em;
        line-height: 0.9em;
        margin-left: -3px;
    }
    
    span.intro-lower {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: Josefin Sans,sans-serif;
        font-weight: 300;
        margin: 10px 0;
        font-size: 1em;
        line-height: .9em;
        pointer-events: none;
    }
    .Home .introduction{
        left: 5%;
    }
  }