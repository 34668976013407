select {
    display: inline-block;
}

ul.navLinks {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin-right:3%;
}

.navBarContainer {
    height: 75px;
    width: 100%;
}

.navbarContent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 2px solid black; */
}

.navLink {
    margin: 20px;
    font-size: 25px;
    color: black;
    background-color: transparent;
    text-decoration: none;
}

.active {
    color: rgb(122, 122, 122);
    background-color: transparent;
    text-decoration: none;
}
  
.navLink:hover {
    color: #cdcdcd;
    background-color: transparent;
    text-decoration: none;
}


.logo {
    display: inline-block;
    margin: 5px;
    margin-left: 5%;
}

.githubLink {
    display: flex;
    flex-direction: column;
    align-items: center;
}

ul {
    list-style-type: none;
}