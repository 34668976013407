.micromasterCertificate {
    display: block;
    width: 584px; 
    height: 414px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    box-shadow: 0px 0px 17px -10px rgba(0,0,0,0.75);
}

.certificates {
    display: inline-block;
    width: 460px; 
    height: 285px; 
    margin: 10px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 17px -10px rgba(0,0,0,0.75);
}

.certificatesContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
    .micromasterCertificate {
        display: block;
        width: 300px; 
        height: 210px; 
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        box-shadow: 0px 0px 17px -10px rgba(0,0,0,0.75);
    }
    .certificates {
        display: inline-block;
        width: 306px; 
        height: 190px; 
        margin: 10px;
        margin-bottom: 50px;
        box-shadow: 0px 0px 17px -10px rgba(0,0,0,0.75);
    }
  }