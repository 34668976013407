.servicesHeading {
    text-align: center;
    margin-top: 50px;
}

.card {
    display: inline-block;
    width: 16rem;
    text-align: center;
    font-size: 16px;
    border: 1px solid black;
    border-radius: 3px;
    margin: 1rem;
    margin-bottom: 40px;
    padding: 1rem;
    background-color: white;
    box-shadow: 0px 0px 17px -10px rgba(0,0,0,0.75);
}

.outerCard {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.devIcons2 {
    font-size: 50px;
}

.servicesP {
    margin-bottom: 50px;
}

@media only screen and (max-width: 800px) {
    .card {
        display: block;
        width: 14rem;
        text-align: center;
        font-size: 16px;
        border: 1px solid black;
        border-radius: 3px;
        margin: auto;
        margin-bottom: 40px;
        padding: 1rem;
        background-color: white;
        box-shadow: 0px 0px 17px -10px rgba(0,0,0,0.75);
    }
    .outerCard {
        display: block;
    }
}