.centreIcons {
    text-align: center;
    margin-top: 120px;
}

.contactHeading {
    text-align: center;
    margin-top: 50px;
}

.contactIcons {
    font-size: 90px;
    margin: 5px;
}

a.contactIcons:link {
    color: black;
    background-color: transparent;
    text-decoration: none;
}

a.contactIcons:visited  {
    color: black;
    background-color: transparent;
    text-decoration: none;
}

a.contactIcons:hover {
    color: rgb(122, 122, 122);
    background-color: transparent;
    text-decoration: none;
}

.iconDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5vw;
    margin-left: 100px;
    margin-right: 100px;
}

.iconDivOuter {
    display: inline-block;
}