.mainSection {
    margin: auto;
    font-size: 18px;
}

.sections {
    width: 70vw;
    margin: auto;
}

.dividers {
    border-top: 2px solid;
    margin: auto;
    margin-bottom: 60px;
    margin-top: 60px;
    width: 70vw;
    color: #cdcdcd;
}

/* ===============================================================================
Text formatting Start
=============================================================================== */

.aboutMeHeading {
    text-align: center;
    margin-top: 50px;
}

.headers {
    margin-top: 20px;
}

p {
    color: #292929;
}

/* ===============================================================================
Text formatting End
=============================================================================== */

/* ===============================================================================
Images and Media Start
=============================================================================== */

.profilePic {
    display: block;
    margin: auto;
    margin-top: 50px;
    /* box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.75);
    border-radius:100px; */
}

/* ===============================================================================
Images and Media End
=============================================================================== */